import axios, { AxiosInstance } from 'axios';
import { onError } from 'services/axios';

export interface AuthLocalStorage {
  accessToken: string;
  refreshToken: string;
}

const publicAxios: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_TEST_MODE
    ? process.env.REACT_APP_BASE_URL
    : process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

const privateAxios: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
});

privateAxios.interceptors.request.use(
  async (config) => ({
    ...config,
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      Accept: 'application/json',
      'Content-Type': config.url?.includes('token')
        ? 'application/x-www-form-urlencoded'
        : 'application/json'
    }
  }),
  (error) => {
    Promise.reject(error);
  }
);

privateAxios.interceptors.response.use(
  (response) => response,
  (error) => onError(error, privateAxios)
);

// This function is used by axios interceptors in this file for API legacy and axios.ts for Python API
// Unfortunately, AuthContext is not available here because we are outside React and hooks are not available
// We must find a way to initialize interceptors in React component, probably by creating a useAxiosInterceptors hook

const handleClearAuthAndLoginRedirect = () => {
  clearAuthStorage();
  return window.location.replace(`${process.env.REACT_APP_BASE_URL}/login`);
};

const clearAuthStorage = () => {
  window.localStorage.removeItem('accessToken');
  window.localStorage.removeItem('refreshToken');
  window.localStorage.removeItem('email');
};

const setNewAuthStorage = ({ accessToken, refreshToken }: AuthLocalStorage) => {
  window.localStorage.setItem('accessToken', accessToken);
  window.localStorage.setItem('refreshToken', refreshToken);
};

export {
  privateAxios,
  publicAxios,
  handleClearAuthAndLoginRedirect,
  clearAuthStorage,
  setNewAuthStorage
};
