import { Outlet } from 'react-router-dom';

import {
  useCustomerQuery,
  useSubscriptionQuery
} from 'api/customer/queries.index';

import { MemberMenu } from 'features/menu';
import { LayoutLoadingSkeleton } from 'features/shared/layout';

export default function AccountPage() {
  // Legacy Code, should be removed when subscriptions / consultations are handled by the new API
  const { data, isSuccess: isCustomerSuccess } = useCustomerQuery();

  const subscription = useSubscriptionQuery({
    customerId: data?.id || 0,
    enabled: isCustomerSuccess
  });

  if (!subscription.isSuccess) return <LayoutLoadingSkeleton />;

  return (
    <div className="min-h-screen bg-gray-200 md:flex">
      <div
        id="sidebar"
        className="md:my-4 md:ml-2 rounded-2xl bg-white md:w-[256px] md:min-w-[256px]"
      >
        <MemberMenu />
      </div>
      <div
        id="content"
        className="mt-[72px] md:mt-0 md:col-span-2 bg-gray-200 z-10 pt-4 md:pt-4 pb-6 w-full"
      >
        <Outlet
          context={{
            subscription: subscription.data
          }}
        />
      </div>
    </div>
  );
}
